function createMarkup(htmlContent) {
    return {__html: htmlContent}
}

function encodeForm(data) {
    return Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
}

function wrapIframe() {
    const iframes = document.querySelectorAll('iframe');

    Array.from(iframes).map((iframe) => {
        const width = iframe.width || parseInt(iframe.style.width);
        const height = iframe.height || parseInt(iframe.style.height);

        if (width || height) {
            const ratio = Math.round(height / (width / 100) * 100) / 100;
            const wrapper = document.createElement('div');

            wrapper.classList.add('embed-responsive');

            wrapper.style = `
                padding-bottom: ${ratio}%;
            `;

            iframe.parentNode.appendChild(wrapper)
            wrapper.appendChild(iframe)
        }

        return null;
    });
};

function setBackgroundStyle(image) {
  return image
  ? {
    backgroundImage: `
      var(--spinner-url),
      url(${image.node.fluid.src}),
      url(${image.node.fluid.base64})
    `,
    backgroundSize: `
      50px 50px,
      cover,
      cover
    `,
    backgroundBlendMode: `
      luminosity,
      normal,
      normal
    `,
  } : {}
}

export {
    createMarkup,
    encodeForm,
    setBackgroundStyle,
    wrapIframe
}
