import React, { useEffect} from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { decodeHTML } from 'entities';
import { createMarkup } from '../lib/util';
import { responsiveRatio } from '@nutriot/responsive-ratio';

import './wp.scss';

const WordpressTemplate = props => {
  const { pageContext } = props;
  const { content, tags, title } = pageContext;

  useEffect(() => {
    responsiveRatio();

    return () => undefined;
  }, []);

  return (
    <Layout fullwidth={true} textblock={true}>
      <SEO title={decodeHTML(title)} keywords={tags} />
      <section className="item post">
        <h1 dangerouslySetInnerHTML={createMarkup(title)}></h1>
        <div dangerouslySetInnerHTML={createMarkup(content)}></div>
      </section>
    </Layout>
  )
}

export default WordpressTemplate
